import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({
  component: Component,
  roles,
  exact = true,
  ...rest
}) => (
  <Route
    {...rest}
    exact={exact}
    render={(props) => {
      const currentUser = JSON.parse(localStorage.getItem("token"));
      if (!currentUser) {
        return <Redirect to={{ pathname: "/login" }} />;
      }

      return <Component exact={exact} {...props} />;
    }}
  />
);
export default PrivateRoute;
