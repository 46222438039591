import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "antd/dist/antd.css";
import { Spin } from "antd";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Suspense
      fallback={
        <div>
          <div className="pageLoader" style={{ height: "100vh" }}>
            <Spin size="large" />
          </div>
        </div>
      }
    >
      <App />
    </Suspense>
  </React.StrictMode>
);

reportWebVitals();
