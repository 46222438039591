import {
  DeleteOutlined,
  EditOutlined,
  LeftOutlined,
  LeftSquareFilled,
  LogoutOutlined,
  PlusOutlined,
  SearchOutlined,
  UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React, { useState, useEffect, useRef } from "react";
import AuthService from "../../service/auth-service";
import { Button, Input, message, Modal, Popconfirm, Switch, Table } from "antd";
import Highlighter from "react-highlight-words";
import { useHistory } from "react-router-dom";
import DistrictService from "../../service/district-service";
import { useForm } from "react-hook-form";

const District = () => {
  const { confirm } = Modal;
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    setIsLoading(true);
    DistrictService.getAllDistrict()
      .then((res) => {
        if (res?.data?.length) {
          setUsers(res?.data);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleSearch = (selectedKeys, confirm = () => {}, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const {
    register,
    reset,
    trigger,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: null,
      name: "",
    },
  });

  const saveUser = (data) => {
    const payload = {
      name: data?.name,
    };

    DistrictService.addDistrict(payload)
      .then(() => {
        message.success("District saved successfully");
        setShowModal(false);
        fetchUsers();
      })
      .catch((err) => {
        message.error(
          err?.response?.data?.errors[0]?.msg || "Something went wrong"
        );
      });
  };

  const deteteUser = (data) => {
    DistrictService.deleteDistrict(data?.id)
      .then(() => {
        fetchUsers();
        message.success("District deleted successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateUser = (data) => {
    const payload = {
      name: data?.name,
    };

    DistrictService.updateDistrict(data?.id, payload)
      .then(() => {
        setShowModal(false);
        fetchUsers();
        message.success("District updated successfully");
      })
      .catch((err) => {
        message.error(
          err?.response?.data?.errors[0]?.msg || "Something went wrong"
        );
      });
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      ...getColumnSearchProps("id"),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Action",
      align: "center",
      render: (text) => (
        <div>
          <Popconfirm
            title="Are you sure edit this District?"
            okText="Yes"
            cancelText="No"
            placement="left"
            onConfirm={() => {
              setValue("id", text?.id);
              setValue("name", text?.name);
              setShowModal(true);
            }}
          >
            <EditOutlined style={{ marginRight: 10 }} />
          </Popconfirm>
          <Popconfirm
            title="Are you sure delete this District?"
            okText="Yes"
            cancelText="No"
            placement="left"
            onConfirm={() => deteteUser(text)}
          >
            <DeleteOutlined style={{ color: "red" }} />
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div style={{ padding: 10 }}>
      <div
        style={{
          marginTop: 10,
          marginBottom: 10,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          type="danger"
          onClick={() => {
            history.goBack();
          }}
          icon={<LeftOutlined />}
          size={20}
        >
          Back
        </Button>
        <Button
          type="primary"
          onClick={() => {
            setShowModal(true);
            reset();
          }}
          size={20}
        >
          Add District
        </Button>
      </div>
      <Table
        dataSource={users}
        columns={columns}
        loading={isLoading}
        pagination={{
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
      />
      <Modal
        title={getValues("id") ? "Update District" : "Add District"}
        open={showModal}
        footer={null}
        onCancel={() => {
          setShowModal(false);
          reset();
        }}
      >
        <div style={{ marginBottom: 8 }}>
          <label style={{ marginBottom: 4 }}>Name</label>
          <Input
            {...register("name", {
              required: {
                value: true,
                message: "Name is required",
              },
            })}
            value={getValues("name")}
            onChange={(e) => {
              setValue("name", e.target.value);
              trigger("name");
            }}
          />
          <span style={{ color: "red" }}>
            {errors?.name && errors?.name?.message}
          </span>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
        >
          <Button
            type="primary"
            onClick={() => {
              trigger().then((res) => {
                if (res) {
                  if (getValues("id")) {
                    updateUser(getValues());
                  } else saveUser(getValues());
                }
              });
            }}
          >
            {getValues("id") ? "Update" : "Save"}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default District;
