import axios from "axios";
import { auth } from "./url";

const login = (body) => {
  return axios.post(auth.LOG_IN, body);
};

const logout = () => {
  localStorage.clear();
  window.location.href = "/login";
};

const AuthService = {
  login,
  logout,
};

export default AuthService;
