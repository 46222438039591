import Axios from "axios";

const TokenInterceptor = (token) => {
  Axios.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        config.headers.Authorization = JSON.parse(
          localStorage.getItem("token")
        );
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
};
export default TokenInterceptor;
