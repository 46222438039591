import URLs from "../config";

export const auth = {
  LOG_IN: URLs.API + "/auth/login",
};
export const profile = {
  LIST: URLs.API + "/profile/list",
  DELETE: URLs.API + "/profile/delete/",
  ADD: URLs.API + "/profile/create/",
  PARTICULAR: URLs.API + "/profile/particular/",
  ADMIN_PARTICULAR: URLs.API + "/profile/admin/particular/",
  UPDATE: URLs.API + "/profile/update/",
};

export const district = {
  DISTRICT: URLs.API + "/district",
};
