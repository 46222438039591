import "antd/dist/antd.css";
import Routes from "./routes";
import { BrowserRouter } from "react-router-dom";
import ErrorInterceptor from "./service/error-interceptor";
import TokenInterceptor from "./service/token-interceptor";

function App() {
  ErrorInterceptor();
  TokenInterceptor();
  return (
    <BrowserRouter basename="/">
      <Routes />
    </BrowserRouter>
  );
}

export default App;
