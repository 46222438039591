import { ArrowLeftOutlined, SaveOutlined } from "@ant-design/icons";
import { Row, Col, Input, Select, DatePicker, Button, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import AWS from "aws-sdk";
import Resizer from "react-image-file-resizer";
import Box from "../../components/Box";
import "./AddProfile.css";
import { Buffer } from "buffer";
import ProfileService from "../../service/profile-service";
import { useHistory } from "react-router-dom";

const AddProfile = () => {
  useEffect(() => {
    fetchDistricts();
  }, []);

  const fetchDistricts = () => {
    ProfileService.getAllDistrict().then((res) => {
      setDistrictDD(res?.data || []);
    });
  };

  const { Option } = Select;
  const { TextArea } = Input;
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  // State
  const [name, setName] = useState("");
  const [occupation, setOccupation] = useState("");
  const [education, setEducation] = useState("");
  const [kullam, setKullam] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [motherName, setMotherName] = useState("");
  const [gender, setGender] = useState("ஆண்");
  const [birthTime, setBirthTime] = useState("");
  const [birthDate, setBirthDate] = useState(moment().format("YYYY/MM/DD"));
  const [birthPlace, setBirthPlace] = useState("");
  const [nativeTown, setNativeTown] = useState("");
  const [fatherOccupation, setFatherOccupation] = useState("");
  const [motherOccupation, setMotherOccupation] = useState("");
  const [sibblings, setSibblings] = useState("");
  const [assets, setAssets] = useState("");
  const [others, setOthers] = useState("");
  const [marriageStatus, setMarriageStatus] = useState("முதல் மணம்");
  const [jathakathinNellai, setJathakathinNellai] = useState("சுத்தம்");
  const [broker, setBroker] = useState("கொங்கு மேட்ரிமோனி");
  const [saathi, setSaathi] = useState("கொங்கு வேளாளக்கவுண்டர்");
  const [kullaDevam, setKullaDevam] = useState("");
  const [workingPlace, setWorkingPlace] = useState("");
  const [monthlyIncome, setMonthlyIncome] = useState("");
  const [color, setColor] = useState("மாநிறம்");
  const [height, setHeight] = useState("");
  const [weight, setweight] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [raasi, setRaasi] = useState("");
  const [nachathiram, setNachathiram] = useState("");
  const [lakknam, setLakknam] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [expectation, setExpectation] = useState("");
  const [varadam, setVaradam] = useState("");
  const [maatham, setMaatham] = useState("");
  const [naal, setNaal] = useState("");
  const [district, setDistrict] = useState(1);
  const [districtDD, setDistrictDD] = useState([]);

  // l1
  const [l1_1, setL1_1] = useState("");
  const [l1_2, setL1_2] = useState("");
  const [l1_3, setL1_3] = useState("");
  const [l1_4, setL1_4] = useState("");

  // l2
  const [l2_1, setL2_1] = useState("");
  const [l2_2, setL2_2] = useState("");
  const [l2_3, setL2_3] = useState("");
  const [l2_4, setL2_4] = useState("");

  // l3
  const [l3_1, setL3_1] = useState("");
  const [l3_2, setL3_2] = useState("");
  const [l3_3, setL3_3] = useState("");
  const [l3_4, setL3_4] = useState("");

  // l4
  const [l4_1, setL4_1] = useState("");
  const [l4_2, setL4_2] = useState("");
  const [l4_3, setL4_3] = useState("");
  const [l4_4, setL4_4] = useState("");

  // l5
  const [l5_1, setL5_1] = useState("");
  const [l5_2, setL5_2] = useState("");
  const [l5_3, setL5_3] = useState("");
  const [l5_4, setL5_4] = useState("");

  // l6
  const [l6_1, setL6_1] = useState("");
  const [l6_2, setL6_2] = useState("");
  const [l6_3, setL6_3] = useState("");
  const [l6_4, setL6_4] = useState("");

  // l7
  const [l7_1, setL7_1] = useState("");
  const [l7_2, setL7_2] = useState("");
  const [l7_3, setL7_3] = useState("");
  const [l7_4, setL7_4] = useState("");

  // l8
  const [l8_1, setL8_1] = useState("");
  const [l8_2, setL8_2] = useState("");
  const [l8_3, setL8_3] = useState("");
  const [l8_4, setL8_4] = useState("");

  // l9
  const [l9_1, setL9_1] = useState("");
  const [l9_2, setL9_2] = useState("");
  const [l9_3, setL9_3] = useState("");
  const [l9_4, setL9_4] = useState("");

  // l10
  const [l10_1, setL10_1] = useState("");
  const [l10_2, setL10_2] = useState("");
  const [l10_3, setL10_3] = useState("");
  const [l10_4, setL10_4] = useState("");

  // l11
  const [l11_1, setL11_1] = useState("");
  const [l11_2, setL11_2] = useState("");
  const [l11_3, setL11_3] = useState("");
  const [l11_4, setL11_4] = useState("");

  // l10
  const [l12_1, setL12_1] = useState("");
  const [l12_2, setL12_2] = useState("");
  const [l12_3, setL12_3] = useState("");
  const [l12_4, setL12_4] = useState("");

  // Right Box

  // r1
  const [r1_1, setR1_1] = useState("");
  const [r1_2, setR1_2] = useState("");
  const [r1_3, setR1_3] = useState("");
  const [r1_4, setR1_4] = useState("");

  // r2
  const [r2_1, setR2_1] = useState("");
  const [r2_2, setR2_2] = useState("");
  const [r2_3, setR2_3] = useState("");
  const [r2_4, setR2_4] = useState("");

  // r3
  const [r3_1, setR3_1] = useState("");
  const [r3_2, setR3_2] = useState("");
  const [r3_3, setR3_3] = useState("");
  const [r3_4, setR3_4] = useState("");

  // r4
  const [r4_1, setR4_1] = useState("");
  const [r4_2, setR4_2] = useState("");
  const [r4_3, setR4_3] = useState("");
  const [r4_4, setR4_4] = useState("");

  // r5
  const [r5_1, setR5_1] = useState("");
  const [r5_2, setR5_2] = useState("");
  const [r5_3, setR5_3] = useState("");
  const [r5_4, setR5_4] = useState("");

  // r6
  const [r6_1, setR6_1] = useState("");
  const [r6_2, setR6_2] = useState("");
  const [r6_3, setR6_3] = useState("");
  const [r6_4, setR6_4] = useState("");

  // r7
  const [r7_1, setR7_1] = useState("");
  const [r7_2, setR7_2] = useState("");
  const [r7_3, setR7_3] = useState("");
  const [r7_4, setR7_4] = useState("");

  // r8
  const [r8_1, setR8_1] = useState("");
  const [r8_2, setR8_2] = useState("");
  const [r8_3, setR8_3] = useState("");
  const [r8_4, setR8_4] = useState("");

  // r9
  const [r9_1, setR9_1] = useState("");
  const [r9_2, setR9_2] = useState("");
  const [r9_3, setR9_3] = useState("");
  const [r9_4, setR9_4] = useState("");

  // r10
  const [r10_1, setR10_1] = useState("");
  const [r10_2, setR10_2] = useState("");
  const [r10_3, setR10_3] = useState("");
  const [r10_4, setR10_4] = useState("");

  // r11
  const [r11_1, setR11_1] = useState("");
  const [r11_2, setR11_2] = useState("");
  const [r11_3, setR11_3] = useState("");
  const [r11_4, setR11_4] = useState("");

  // r10
  const [r12_1, setR12_1] = useState("");
  const [r12_2, setR12_2] = useState("");
  const [r12_3, setR12_3] = useState("");
  const [r12_4, setR12_4] = useState("");

  //For Image
  const [file, setFile] = useState("");
  const [fileURL, setFileURL] = useState("");
  const [imageData, setImageData] = useState("");
  const [imageName, setImageName] = useState(
    "https://s3.ap-south-1.amazonaws.com/kongumatrimony.co.in/3295d955.jpeg"
  );

  const handelImageChange = async (e) => {
    const file = e.target.files[0];
    await Resizer.imageFileResizer(
      e.target.files[0],
      900,
      900,
      "JPEG",
      90,
      0,
      (uri) => {
        setImageData(uri);
        handleUpload(uri);
      },
      "base64"
    );
    setFileURL(URL.createObjectURL(file));
    setFile(file);
  };

  //Handle Upload
  const handleUpload = (imageData) => {
    message.destroy();
    message.loading("Image Uploading...");
    setIsLoading(true);
    AWS.config.update({
      accessKeyId: "AKIAWLWUM4SL5C3ZYGMX",
      secretAccessKey: "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",
      region: "ap-south-1",
    });
    var s3 = new AWS.S3();
    const base64Data = new Buffer.from(
      imageData.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    let name = Math.random().toString().slice(10);
    setImageName(
      "https://s3.ap-south-1.amazonaws.com/kongumatrimony.co.in/" +
        name +
        ".jpeg"
    );
    s3.putObject({
      Bucket: "kongumatrimony.co.in",
      Body: base64Data,
      Key: name + ".jpeg",
      ACL: "public-read",
    })
      .promise()
      .then(() => {
        message.destroy();
        message.success("Image Upload Successfully!");
        setIsLoading(false);
      })
      .catch((error) => {
        message.destroy();
        message.error("Image Upload Unsuccessful!");
        setIsLoading(false);
      });
  };

  const saveProfile = () => {
    let params = {
      name,
      occupation,
      education,
      kullam,
      others,
      father_name: fatherName,
      mother_name: motherName,
      gender: gender,
      birth_time: birthTime,
      birth_date: birthDate,
      birth_place: birthPlace,
      native_town: nativeTown,
      father_occupation: fatherOccupation,
      mother_occupation: motherOccupation,
      sibblings: sibblings,
      assets: assets,
      marriage_status: marriageStatus,
      jathakathin_nellai: jathakathinNellai,
      broker: broker,
      saathi: saathi,
      kulla_devam: kullaDevam,
      working_place: workingPlace,
      monthly_income: monthlyIncome,
      color: color,
      height: height,
      weight: weight,
      blood_group: bloodGroup,
      raasi: raasi,
      nachathiram: nachathiram,
      lakknam: lakknam,
      mobile: mobile,
      address: address,
      expectation,
      varadam: varadam,
      maatham: maatham,
      naal: naal,
      district: district,

      l1_1: l1_1,
      l1_2: l1_2,
      l1_3: l1_3,
      l1_4: l1_4,

      l2_1: l2_1,
      l2_2: l2_2,
      l2_3: l2_3,
      l2_4: l2_4,

      l3_1: l3_1,
      l3_2: l3_2,
      l3_3: l3_3,
      l3_4: l3_4,

      l4_1: l4_1,
      l4_2: l4_2,
      l4_3: l4_3,
      l4_4: l4_4,

      l5_1: l5_1,
      l5_2: l5_2,
      l5_3: l5_3,
      l5_4: l5_4,

      l6_1: l6_1,
      l6_2: l6_2,
      l6_3: l6_3,
      l6_4: l6_4,

      l7_1: l7_1,
      l7_2: l7_2,
      l7_3: l7_3,
      l7_4: l7_4,

      l8_1: l8_1,
      l8_2: l8_2,
      l8_3: l8_3,
      l8_4: l8_4,

      l9_1: l9_1,
      l9_2: l9_2,
      l9_3: l9_3,
      l9_4: l9_4,

      l10_1: l10_1,
      l10_2: l10_2,
      l10_3: l10_3,
      l10_4: l10_4,

      l11_1: l11_1,
      l11_2: l11_2,
      l11_3: l11_3,
      l11_4: l11_4,

      l12_1: l12_1,
      l12_2: l12_2,
      l12_3: l12_3,
      l12_4: l12_4,

      r1_1: r1_1,
      r1_2: r1_2,
      r1_3: r1_3,
      r1_4: r1_4,

      r2_1: r2_1,
      r2_2: r2_2,
      r2_3: r2_3,
      r2_4: r2_4,

      r3_1: r3_1,
      r3_2: r3_2,
      r3_3: r3_3,
      r3_4: r3_4,

      r4_1: r4_1,
      r4_2: r4_2,
      r4_3: r4_3,
      r4_4: r4_4,

      r5_1: r5_1,
      r5_2: r5_2,
      r5_3: r5_3,
      r5_4: r5_4,

      r6_1: r6_1,
      r6_2: r6_2,
      r6_3: r6_3,
      r6_4: r6_4,

      r7_1: r7_1,
      r7_2: r7_2,
      r7_3: r7_3,
      r7_4: r7_4,

      r8_1: r8_1,
      r8_2: r8_2,
      r8_3: r8_3,
      r8_4: r8_4,

      r9_1: r9_1,
      r9_2: r9_2,
      r9_3: r9_3,
      r9_4: r9_4,

      r10_1: r10_1,
      r10_2: r10_2,
      r10_3: r10_3,
      r10_4: r10_4,

      r11_1: r11_1,
      r11_2: r11_2,
      r11_3: r11_3,
      r11_4: r11_4,

      r12_1: r12_1,
      r12_2: r12_2,
      r12_3: r12_3,
      r12_4: r12_4,
      imageUrl: imageName,
      others: others,
    };
    setIsLoading(true);

    ProfileService.addProfile(params)
      .then(() => {
        message.destroy();
        message.success("Profile Added Successfully!");
        setIsLoading(false);
        history.goBack();
      })
      .catch((e) => {
        message.destroy();
        message.error("Profile Save Unsuccessful");
        setIsLoading(false);
        console.log(e);
      });
  };
  return (
    <div className="add-profile">
      <div className="header">
        <ArrowLeftOutlined onClick={() => history.goBack()} />
        <span>Add Profile</span>
      </div>
      <Row style={{ padding: 20 }}>
        <Col span={11} xs={24} sm={24} md={11} lg={11} xl={11}>
          <div
            style={{
              height: 400,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {fileURL != "" && (
              <img
                src={imageData}
                alt="Uploaded Image"
                style={{ margin: 20, maxHeight: 300, objectFit: "cover" }}
              />
            )}
            <input
              accept="image/*"
              type="file"
              onChange={(e) => handelImageChange(e)}
            />
          </div>
        </Col>
        <Col span={2} xs={0} sm={0} md={2} lg={2} xl={2} />
        <Col span={11} xs={24} sm={24} md={11} lg={11} xl={11}>
          <label>பெயர்</label>
          <Input
            placeholder="பெயர்"
            className="form-control"
            size="large"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <label>தொழில்</label>
          <Input
            placeholder="தொழில்"
            className="form-control"
            size="large"
            value={occupation}
            onChange={(e) => setOccupation(e.target.value)}
          />
          <Col>
            <label>தொழில் வகை</label>
            <Select
              style={{ width: "100%", marginBottom: 14 }}
              size="large"
              showSearch
              placeholder="தொழில் வகை"
              defaultValue={""}
              value={others}
              onChange={(e) => setOthers(e)}
            >
              <Option value="பொறியாளர்கள்">பொறியாளர்கள்</Option>
              <Option value="டாக்டர்கள்">டாக்டர்கள்</Option>
              <Option value="அரசு வேலை">அரசு வேலை</Option>
              <Option value="சொந்த தொழில்">சொந்த தொழில்</Option>
              <Option value="மற்றவை">மற்றவை</Option>
            </Select>
          </Col>
          <label>படிப்பு</label>
          <Input
            placeholder="படிப்பு"
            className="form-control"
            size="large"
            value={education}
            onChange={(e) => setEducation(e.target.value)}
          />
          <label>குலம்</label>
          <Input
            placeholder="குலம்"
            className="form-control"
            size="large"
            value={kullam}
            onChange={(e) => setKullam(e.target.value)}
          />
          <label>குலதெய்வம்</label>
          <Input
            placeholder="குலதெய்வம்"
            className="form-control"
            size="large"
            value={kullaDevam}
            onChange={(e) => setKullaDevam(e.target.value)}
          />
        </Col>
      </Row>
      <div className="ruler" />
      <Row style={{ padding: 20 }}>
        <Col span={11} xs={24} sm={24} md={11} lg={11} xl={11}>
          <label>தந்தை பெயர்</label>
          <Input
            placeholder="தந்தை பெயர்"
            className="form-control"
            size="large"
            value={fatherName}
            onChange={(e) => setFatherName(e.target.value)}
          />
          <label>தாயாா் பெயர்</label>
          <Input
            placeholder="தாயாா் பெயர்"
            className="form-control"
            size="large"
            value={motherName}
            onChange={(e) => setMotherName(e.target.value)}
          />
          <Col>
            <label>பாலினம்</label>
            <Select
              style={{ width: "100%", marginBottom: 14 }}
              size="large"
              showSearch
              placeholder="பாலினம்"
              defaultValue={""}
              value={gender}
              onChange={(e) => setGender(e)}
            >
              <Option value="ஆண்">ஆண்</Option>
              <Option value="பெண்">பெண்</Option>
            </Select>
          </Col>
          <label>பிறந்த நேரம்</label>
          <Input
            placeholder="பிறந்த நேரம்"
            className="form-control"
            size="large"
            value={birthTime}
            onChange={(e) => setBirthTime(e.target.value)}
          />
          <Col>
            <label>பிறந்த தேதி</label>
            <DatePicker
              defaultValue={moment()}
              format={"DD/MM/YYYY"}
              placeholder="பிறந்த தேதி"
              size={"large"}
              style={{ width: "100%", marginBottom: 14 }}
              onChange={(e) => {
                if (e) setBirthDate(moment(e).format("YYYY-MM-DD"));
                if (e == null) setBirthDate(moment().format("YYYY-MM-DD"));
              }}
            />
          </Col>
          <label>பிறந்த இடம்</label>
          <Input
            placeholder="பிறந்த இடம்"
            className="form-control"
            size="large"
            value={birthPlace}
            onChange={(e) => setBirthPlace(e.target.value)}
          />
          <label>சொந்த ஊர்</label>
          <Input
            placeholder="சொந்த ஊர்"
            className="form-control"
            size="large"
            value={nativeTown}
            onChange={(e) => setNativeTown(e.target.value)}
          />
          <label>மாவட்டம்</label>
          <div className="form-control">
            <Select
              style={{ width: "100%" }}
              placeholder=""
              value={district}
              onChange={(e) => {
                setDistrict(e);
              }}
            >
              {districtDD.map((a) => {
                return <Select.Option value={a?.id}>{a?.name}</Select.Option>;
              })}
            </Select>
          </div>
          <label>தந்தை தொழில்</label>
          <Input
            placeholder="தந்தை தொழில்"
            className="form-control"
            size="large"
            value={fatherOccupation}
            onChange={(e) => setFatherOccupation(e.target.value)}
          />
          <label>தாயாா் தொழில்</label>
          <Input
            placeholder="தாயாா் தொழில்"
            className="form-control"
            size="large"
            value={motherOccupation}
            onChange={(e) => setMotherOccupation(e.target.value)}
          />
          <label>உடன் பிறப்பு</label>
          <Input
            placeholder="உடன் பிறப்பு"
            className="form-control"
            size="large"
            value={sibblings}
            onChange={(e) => setSibblings(e.target.value)}
          />
          <label>சொத்து விவரம்</label>
          <TextArea
            rows={4}
            placeholder="சொத்து விவரம்"
            className="form-control"
            size="large"
            value={assets}
            onChange={(e) => setAssets(e.target.value)}
          />
          <Col>
            <label>திருமண நிலை</label>
            <Select
              style={{ width: "100%", marginBottom: 14 }}
              size="large"
              showSearch
              placeholder="திருமண நிலை"
              defaultValue={"முதல் மணம்"}
              value={marriageStatus}
              onChange={(e) => setMarriageStatus(e)}
            >
              <Option value="முதல் மணம்">முதல் மணம்</Option>
              <Option value="மறுமணம்">மறுமணம்</Option>
            </Select>
          </Col>
          <Col>
            <label>ஜாதகத்தின் நிலை</label>
            <Select
              style={{ width: "100%", marginBottom: 14 }}
              size="large"
              showSearch
              placeholder="ஜாதகத்தின் நிலை"
              defaultValue={""}
              value={jathakathinNellai}
              onChange={(e) => setJathakathinNellai(e)}
            >
              <Option value="செவ்வாய்">செவ்வாய்</Option>
              <Option value="சுத்தம்">சுத்தம்</Option>
              <Option value="ராகு கேது">ராகு கேது</Option>
              <Option value="ராகு கேது செவ்வாய்">ராகு கேது செவ்வாய்</Option>
            </Select>
          </Col>
          <label>அமைப்பாளர்</label>
          <Input
            placeholder="அமைப்பாளர்"
            className="form-control"
            size="large"
            value={broker}
            onChange={(e) => setBroker(e.target.value)}
          />
        </Col>
        <Col span={2} xs={0} sm={0} md={2} lg={2} xl={2} />
        <Col span={11} xs={24} sm={24} md={11} lg={11} xl={11}>
          <label>ஜாதி</label>
          <Input
            placeholder="ஜாதி"
            className="form-control"
            size="large"
            value={saathi}
            onChange={(e) => setSaathi(e.target.value)}
          />

          <label>வேலை செய்யும் இடம்</label>
          <Input
            placeholder="வேலை செய்யும் இடம்"
            className="form-control"
            size="large"
            value={workingPlace}
            onChange={(e) => setWorkingPlace(e.target.value)}
          />
          <label>மாத வருமானம்</label>
          <Input
            placeholder="மாத வருமானம்"
            className="form-control"
            size="large"
            value={monthlyIncome}
            onChange={(e) => setMonthlyIncome(e.target.value)}
          />
          <label>நிறம்</label>
          <Select
            style={{ width: "100%", marginBottom: 14 }}
            size="large"
            showSearch
            placeholder="நிறம்"
            defaultValue={"மாநிறம்"}
            value={color}
            onChange={(e) => setColor(e)}
          >
            <Option value="மாநிறம்">மாநிறம்</Option>
            <Option value="சிவப்பு">சிவப்பு</Option>
          </Select>
          <label>உயரம்</label>
          <Input
            placeholder="உயரம்"
            className="form-control"
            size="large"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
          />
          <label>எடை</label>
          <Input
            placeholder="எடை"
            className="form-control"
            size="large"
            value={weight}
            onChange={(e) => setweight(e.target.value)}
          />
          <label>இரத்தப்பிாிவு</label>
          <Input
            placeholder="இரத்தப்பிாிவு"
            className="form-control"
            size="large"
            value={bloodGroup}
            onChange={(e) => setBloodGroup(e.target.value)}
          />
          <label>நட்சத்திரம்</label>
          <Input
            placeholder="நட்சத்திரம்"
            className="form-control"
            size="large"
            value={nachathiram}
            onChange={(e) => setNachathiram(e.target.value)}
          />
          <Col>
            <label>ராசி</label>
            <Select
              style={{ width: "100%", marginBottom: 14 }}
              size="large"
              showSearch
              placeholder="ராசி"
              value={raasi}
              onChange={(e) => setRaasi(e)}
            >
              <Option value={"மேஷம்"}>மேஷம்</Option>
              <Option value={"ரிஷபம்"}>ரிஷபம்</Option>
              <Option value={"மிதுனம்"}>மிதுனம்</Option>
              <Option value={"கடகம்"}>கடகம்</Option>
              <Option value={"சிம்மம்"}>சிம்மம்</Option>
              <Option value={"கன்னி"}>கன்னி</Option>
              <Option value={"துலாம்"}>துலாம்</Option>
              <Option value={"விருச்சிகம்"}>விருச்சிகம்</Option>
              <Option value={"தனுசு"}>தனுசு</Option>
              <Option value={"மகரம்"}>மகரம்</Option>
              <Option value={"கும்பம்"}>கும்பம்</Option>
              <Option value={"மீனம்"}>மீனம்</Option>
            </Select>
          </Col>
          <Col>
            <label>லக்கினம்</label>
            <Select
              style={{ width: "100%", marginBottom: 14 }}
              size="large"
              showSearch
              placeholder="லக்கினம்"
              value={lakknam}
              onChange={(e) => setLakknam(e)}
            >
              <Option value={"மேஷம்"}>மேஷம்</Option>
              <Option value={"ரிஷபம்"}>ரிஷபம்</Option>
              <Option value={"மிதுனம்"}>மிதுனம்</Option>
              <Option value={"கடகம்"}>கடகம்</Option>
              <Option value={"சிம்மம்"}>சிம்மம்</Option>
              <Option value={"கன்னி"}>கன்னி</Option>
              <Option value={"துலாம்"}>துலாம்</Option>
              <Option value={"விருச்சிகம்"}>விருச்சிகம்</Option>
              <Option value={"தனுசு"}>தனுசு</Option>
              <Option value={"மகரம்"}>மகரம்</Option>
              <Option value={"கும்பம்"}>கும்பம்</Option>
              <Option value={"மீனம்"}>மீனம்</Option>
            </Select>
          </Col>
          <label>அலைபேசி எண்</label>
          <Input
            placeholder="அலைபேசி எண்"
            className="form-control"
            size="large"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
          <label>முகவரி</label>
          <TextArea
            rows={4}
            placeholder="முகவரி"
            className="form-control"
            size="large"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <label>எதிர்பார்ப்பு</label>
          <TextArea
            rows={4}
            placeholder="எதிர்பார்ப்பு"
            className="form-control"
            size="large"
            value={expectation}
            onChange={(e) => setExpectation(e.target.value)}
          />
          <label>வருடம்</label>
          <Input
            placeholder="வருடம்"
            className="form-control"
            size="large"
            value={varadam}
            onChange={(e) => setVaradam(e.target.value)}
          />
          <label>மாதம்</label>
          <Input
            placeholder="மாதம்"
            className="form-control"
            size="large"
            value={maatham}
            onChange={(e) => setMaatham(e.target.value)}
          />
          <label>நாள்</label>
          <Input
            placeholder="நாள்"
            className="form-control"
            size="large"
            value={naal}
            onChange={(e) => setNaal(e.target.value)}
          />
        </Col>
      </Row>
      <div className="ruler" />

      <Row style={{ padding: 20 }}>
        <Col span={11} xs={24} sm={24} md={11} lg={11} xl={11}>
          <table id="table">
            <tbody>
              <tr>
                <td>
                  <Box
                    value1={l1_1}
                    value2={l1_2}
                    value3={l1_3}
                    value4={l1_4}
                    set1={setL1_1}
                    set2={setL1_2}
                    set3={setL1_3}
                    set4={setL1_4}
                  />
                </td>
                <td>
                  <Box
                    value1={l2_1}
                    value2={l2_2}
                    value3={l2_3}
                    value4={l2_4}
                    set1={setL2_1}
                    set2={setL2_2}
                    set3={setL2_3}
                    set4={setL2_4}
                  />
                </td>
                <td>
                  <Box
                    value1={l3_1}
                    value2={l3_2}
                    value3={l3_3}
                    value4={l3_4}
                    set1={setL3_1}
                    set2={setL3_2}
                    set3={setL3_3}
                    set4={setL3_4}
                  />
                </td>
                <td>
                  <Box
                    value1={l4_1}
                    value2={l4_2}
                    value3={l4_3}
                    value4={l4_4}
                    set1={setL4_1}
                    set2={setL4_2}
                    set3={setL4_3}
                    set4={setL4_4}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Box
                    value1={l5_1}
                    value2={l5_2}
                    value3={l5_3}
                    value4={l5_4}
                    set1={setL5_1}
                    set2={setL5_2}
                    set3={setL5_3}
                    set4={setL5_4}
                  />
                </td>
                <th colSpan="2" rowSpan="2">
                  ராசி
                </th>
                <td>
                  <Box
                    value1={l6_1}
                    value2={l6_2}
                    value3={l6_3}
                    value4={l6_4}
                    set1={setL6_1}
                    set2={setL6_2}
                    set3={setL6_3}
                    set4={setL6_4}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Box
                    value1={l7_1}
                    value2={l7_2}
                    value3={l7_3}
                    value4={l7_4}
                    set1={setL7_1}
                    set2={setL7_2}
                    set3={setL7_3}
                    set4={setL7_4}
                  />
                </td>
                <td>
                  <Box
                    value1={l8_1}
                    value2={l8_2}
                    value3={l8_3}
                    value4={l8_4}
                    set1={setL8_1}
                    set2={setL8_2}
                    set3={setL8_3}
                    set4={setL8_4}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Box
                    value1={l9_1}
                    value2={l9_2}
                    value3={l9_3}
                    value4={l9_4}
                    set1={setL9_1}
                    set2={setL9_2}
                    set3={setL9_3}
                    set4={setL9_4}
                  />
                </td>
                <td>
                  <Box
                    value1={l10_1}
                    value2={l10_2}
                    value3={l10_3}
                    value4={l10_4}
                    set1={setL10_1}
                    set2={setL10_2}
                    set3={setL10_3}
                    set4={setL10_4}
                  />
                </td>
                <td>
                  <Box
                    value1={l11_1}
                    value2={l11_2}
                    value3={l11_3}
                    value4={l11_4}
                    set1={setL11_1}
                    set2={setL11_2}
                    set3={setL11_3}
                    set4={setL11_4}
                  />
                </td>
                <td>
                  <Box
                    value1={l12_1}
                    value2={l12_2}
                    value3={l12_3}
                    value4={l12_4}
                    set1={setL12_1}
                    set2={setL12_2}
                    set3={setL12_3}
                    set4={setL12_4}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
        <Col span={2} xs={0} sm={0} md={2} lg={2} xl={2} />
        <Col span={11} xs={24} sm={24} md={11} lg={11} xl={11}>
          <table id="table">
            <tbody>
              <tr>
                <td>
                  <Box
                    value1={r1_1}
                    value2={r1_2}
                    value3={r1_3}
                    value4={r1_4}
                    set1={setR1_1}
                    set2={setR1_2}
                    set3={setR1_3}
                    set4={setR1_4}
                  />
                </td>
                <td>
                  <Box
                    value1={r2_1}
                    value2={r2_2}
                    value3={r2_3}
                    value4={r2_4}
                    set1={setR2_1}
                    set2={setR2_2}
                    set3={setR2_3}
                    set4={setR2_4}
                  />
                </td>
                <td>
                  <Box
                    value1={r3_1}
                    value2={r3_2}
                    value3={r3_3}
                    value4={r3_4}
                    set1={setR3_1}
                    set2={setR3_2}
                    set3={setR3_3}
                    set4={setR3_4}
                  />
                </td>
                <td>
                  <Box
                    value1={r4_1}
                    value2={r4_2}
                    value3={r4_3}
                    value4={r4_4}
                    set1={setR4_1}
                    set2={setR4_2}
                    set3={setR4_3}
                    set4={setR4_4}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Box
                    value1={r5_1}
                    value2={r5_2}
                    value3={r5_3}
                    value4={r5_4}
                    set1={setR5_1}
                    set2={setR5_2}
                    set3={setR5_3}
                    set4={setR5_4}
                  />
                </td>
                <th colSpan="2" rowSpan="2">
                  அம்சம்
                </th>
                <td>
                  <Box
                    value1={r6_1}
                    value2={r6_2}
                    value3={r6_3}
                    value4={r6_4}
                    set1={setR6_1}
                    set2={setR6_2}
                    set3={setR6_3}
                    set4={setR6_4}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Box
                    value1={r7_1}
                    value2={r7_2}
                    value3={r7_3}
                    value4={r7_4}
                    set1={setR7_1}
                    set2={setR7_2}
                    set3={setR7_3}
                    set4={setR7_4}
                  />
                </td>
                <td>
                  <Box
                    value1={r8_1}
                    value2={r8_2}
                    value3={r8_3}
                    value4={r8_4}
                    set1={setR8_1}
                    set2={setR8_2}
                    set3={setR8_3}
                    set4={setR8_4}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Box
                    value1={r9_1}
                    value2={r9_2}
                    value3={r9_3}
                    value4={r9_4}
                    set1={setR9_1}
                    set2={setR9_2}
                    set3={setR9_3}
                    set4={setR9_4}
                  />
                </td>
                <td>
                  <Box
                    value1={r10_1}
                    value2={r10_2}
                    value3={r10_3}
                    value4={r10_4}
                    set1={setR10_1}
                    set2={setR10_2}
                    set3={setR10_3}
                    set4={setR10_4}
                  />
                </td>
                <td>
                  <Box
                    value1={r11_1}
                    value2={r11_2}
                    value3={r11_3}
                    value4={r11_4}
                    set1={setR11_1}
                    set2={setR11_2}
                    set3={setR11_3}
                    set4={setR11_4}
                  />
                </td>
                <td>
                  <Box
                    value1={r12_1}
                    value2={r12_2}
                    value3={r12_3}
                    value4={r12_4}
                    set1={setR12_1}
                    set2={setR12_2}
                    set3={setR12_3}
                    set4={setR12_4}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>

      <div className="ruler" />
      <div className="btn-div">
        <Button
          type="primary"
          loading={isLoading}
          icon={<SaveOutlined />}
          size={"large"}
          onClick={() => saveProfile()}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default AddProfile;
