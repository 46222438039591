import axios from "axios";
import { district } from "./url";

const getAllDistrict = (body) => {
  return axios.get(district.DISTRICT, body);
};
const addDistrict = (body) => {
  return axios.post(district.DISTRICT, body);
};
const deleteDistrict = (id) => {
  return axios.delete(district.DISTRICT + "/" + id);
};
const updateDistrict = (id, data) => {
  return axios.put(district.DISTRICT + "/" + id, data);
};

const DistrictService = {
  getAllDistrict,
  addDistrict,
  deleteDistrict,
  updateDistrict,
};

export default DistrictService;
