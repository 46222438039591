import { message } from "antd";
import axios from "axios";
import AuthService from "./auth-service";

const ErrorInterceptor = () => {
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      message.config({
        duration: 2,
        maxCount: 1,
      });
      let statusCode = error?.response?.status;
      if (statusCode == 403) {
        message.error("Login expired!");
        AuthService.logout();
        return;
      }
      let response = error?.response;
      message.error(response?.data?.message || "Somthing Went Wrong!");
      return Promise.reject(error);
    }
  );
};

export default ErrorInterceptor;
