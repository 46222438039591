import React from "react";
import { Select } from "antd";
const Box = ({ value1, set1, value2, set2, value3, set3, value4, set4 }) => {
  const { Option } = Select;
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Select
        showSearch
        defaultValue={""}
        value={value1}
        onChange={(e) => set1(e)}
      >
        <Option value=""></Option>
        <Option value="சூரிய">சூரிய</Option>
        <Option value="சந்">சந்</Option>
        <Option value="செவ்">செவ்</Option>
        <Option value="புத">புத</Option>
        <Option value="குரு">குரு</Option>
        <Option value="சுக்">சுக்</Option>
        <Option value="சனி">சனி</Option>
        <Option value="ராகு">ராகு</Option>
        <Option value="கேது">கேது</Option>
        <Option value="மாந்">மாந்</Option>
        <Option value="லக்">லக்</Option>
      </Select>
      <Select
        showSearch
        defaultValue={""}
        value={value2}
        onChange={(e) => set2(e)}
      >
        <Option value=""></Option>
        <Option value="சூரிய">சூரிய</Option>
        <Option value="சந்">சந்</Option>
        <Option value="செவ்">செவ்</Option>
        <Option value="புத">புத</Option>
        <Option value="குரு">குரு</Option>
        <Option value="சுக்">சுக்</Option>
        <Option value="சனி">சனி</Option>
        <Option value="ராகு">ராகு</Option>
        <Option value="கேது">கேது</Option>
        <Option value="மாந்">மாந்</Option>
        <Option value="லக்">லக்</Option>
      </Select>
      <Select
        showSearch
        defaultValue={""}
        value={value3}
        onChange={(e) => set3(e)}
      >
        <Option value=""></Option>
        <Option value="சூரிய">சூரிய</Option>
        <Option value="சந்">சந்</Option>
        <Option value="செவ்">செவ்</Option>
        <Option value="புத">புத</Option>
        <Option value="குரு">குரு</Option>
        <Option value="சுக்">சுக்</Option>
        <Option value="சனி">சனி</Option>
        <Option value="ராகு">ராகு</Option>
        <Option value="கேது">கேது</Option>
        <Option value="மாந்">மாந்</Option>
        <Option value="லக்">லக்</Option>
      </Select>
      <Select
        showSearch
        defaultValue={""}
        value={value4}
        onChange={(e) => set4(e)}
      >
        <Option value=""></Option>
        <Option value="சூரிய">சூரிய</Option>
        <Option value="சந்">சந்</Option>
        <Option value="செவ்">செவ்</Option>
        <Option value="புத">புத</Option>
        <Option value="குரு">குரு</Option>
        <Option value="சுக்">சுக்</Option>
        <Option value="சனி">சனி</Option>
        <Option value="ராகு">ராகு</Option>
        <Option value="கேது">கேது</Option>
        <Option value="மாந்">மாந்</Option>
        <Option value="லக்">லக்</Option>
      </Select>
    </div>
  );
};

export default Box;
